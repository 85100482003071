@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.bulletpoint {
  padding-left: 17px !important;
  margin: 0px !important;
}

html,
body {
  padding: 0 !important;
  margin: 0 !important;
  transition: 0s !important;
  font-family: "Nunito", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

button:focus {
  outline: none !important;
}

.flex {
  display: flex;
}

.routes-wrapper {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  min-width: 0;
  position: relative;
}

body > #root > div {
  min-height: 100vh;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

.gradient-to-top {
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.6)
  );
  backdrop-filter: blur(4px);
}

.gradient-to-bottom {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.6)
  );
  backdrop-filter: blur(4px);
}

.opacityOnHover-50,
.elevateOnHover,
.transition,
.fullTransition,
.fullTransition * * {
  transition: all 0.3s ease;
}

.del {
  text-decoration: line-through;
}

*:not(.showScrollBar):not(.MuiDataGrid-virtualScroller)::-webkit-scrollbar {
  display: none;
}

.overflowY {
  overflow-y: scroll !important;
}

.opacity-0 {
  opacity: 0;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-70 {
  opacity: 0.7;
}

.opacityOnHover-50:hover {
  opacity: 0.5;
}

.noElevation {
  box-shadow: none !important;
}

.elevated-4 {
  box-shadow: rgb(0, 0, 0, 0.04) 0px 2px 11px 0px !important;
}

.elevated-8 {
  box-shadow: rgb(0, 0, 0, 0.08) 0px 2px 11px 0px !important;
}

.elevated-12 {
  box-shadow: rgb(0, 0, 0, 0.12) 0px 2px 11px 0px !important;
}

.elevated-16 {
  box-shadow: rgb(0, 0, 0, 0.16) 0px 2px 11px 0px !important;
}

.elevateOnHover:hover {
  box-shadow: rgb(0, 0, 0, 0.16) 0px 2px 11px 0px !important;
}

.dev {
  border: 1px solid black;
}

.outlined-light {
  border: 1px solid #f2f2f2 !important;
}

.outlined-dark {
  border: 1px solid #000000 !important;
}

.outlined-only {
  border: 1px solid #e2e2e2 !important;
}

.outlined {
  transition: border 0.3s ease;
  border: 1px solid #e2e2e2 !important;
}

.outlined-error {
  transition: border 0.3s ease;
  border: 1px solid #ed7273 !important;
}

.outlined-error:hover {
  border: 1px solid #d32f2f !important;
}

.outlined:hover {
  border: 1px solid #000000 !important;
}

.deleted {
  text-decoration: line-through;
}

.underlined {
  text-decoration: underline;
}

[class*="ellipsis-"] {
  white-space: pre-line;
  max-width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: normal;
  overflow-wrap: anywhere;
}

.ellipsis-1 {
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

.ellipsis-2 {
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.ellipsis-3 {
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.whiteBg {
  background-color: #ffffff !important;
}

.blackBg {
  color: #ffffff !important;
  background-color: #000000 !important;
}

.greyBgF2 {
  background-color: #f2f2f2 !important;
}

.greyBgF9 {
  background-color: #f9f9f9 !important;
}

.redBg {
  color: white;
  background-color: #ed7273 !important;
}

.yellowBg {
  background-color: #ffde6f !important;
}

.lightRedBg {
  background-color: #fdf1f1 !important;
}

.lightBlueBg {
  background-color: #e6fbff !important;
}

.lightYellowBg {
  background-color: #ffde6f66 !important;
}

.blueBg {
  background-color: #77e0ff !important;
}

.button {
  cursor: pointer;
  user-select: none;
}

.json {
  white-space: pre-line;
}

.borderRadius-4 {
  border-radius: 4px;
}

.borderRadius-8 {
  border-radius: 8px;
}

.borderRadius-16 {
  border-radius: 16px;
}

.borderRadiusTop-8 {
  border-radius: 8px 8px 0px 0px;
}

.borderRadiusTop-16 {
  border-radius: 16px 16px 0px 0px;
}

.borderRadiusBottom-8 {
  border-radius: 0px 0px 8px 8px;
}

.borderRadiusBottom-16 {
  border-radius: 0px 0px 16px 16px;
}

.borderRadius-20 {
  border-radius: 20px;
}

.borderRadius-100 {
  border-radius: 100%;
}

.hoverOpacityParent:hover .hoverOpacityChild {
  opacity: 1;
}

.textPrimary {
  color: #000000 !important;
}
