.MuiChartsTooltip-root {
  box-shadow: rgb(0, 0, 0, 0.08) 0px 2px 11px 0px !important;
  border: none !important;
  padding: 8px 16px 8px 12px !important;
  border-radius: 8px !important;
}

.MuiChartsTooltip-markCell {
  padding: 0px !important;
}

.MuiChartsTooltip-labelCell {
  color: #000000 !important;
  padding: 0px 8px !important;
}

.MuiChartsTooltip-valueCell {
  padding: 0px !important;
  font-weight: bold !important;
}
