#notistack-snackbar {
  flex: 1;
}

.notistack-SnackbarContainer,
.notistack-SnackbarContainer * {
  transition: initial;
}

.notistack-SnackbarContainer :not(.noMinWidth) {
  min-width: auto;
}

.notistack-SnackbarContainer > div {
  width: 100%;
}

.notistack-SnackbarContainer {
  min-height: 0 !important;
}
