.MuiDataGrid-root {
  border: 1px solid #f2f2f2 !important;
  border-radius: 16px !important;
}

.MuiDataGrid-pinnedColumnHeaders {
  background-color: #e2e2e2 !important;
  border-color: #f2f2f2 !important;
}

.MuiDataGrid-columnHeaders {
  background-color: #f9f9f9 !important;
  border-top-right-radius: 16px !important;
  border-top-left-radius: 16px !important;
  border-color: #f2f2f2 !important;
  z-index: 1 !important;
}

.MuiDataGrid-editInputCell {
  border-radius: 8px !important;
  min-height: 40px !important;
  width: 100%;
  margin: 0px 8px !important;
  box-shadow: rgb(0, 0, 0, 0.08) 0px 2px 11px 0px !important;
  outline: 1px solid #f2f2f2 !important;
}

.stickyHeaders .MuiDataGrid-columnHeaders {
  position: sticky !important;
  top: 80px !important;
}

.MuiDataGrid-row--deleted {
  opacity: 0.5 !important;
}

.MuiDataGrid-main {
  overflow: visible !important;
}

.MuiDataGrid-columnHeaderTitle {
  color: #666 !important;
  font-weight: 600 !important;
}

.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus,
.MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiDataGrid-columnHeader,
.MuiDataGrid-cell--textLeft {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.MuiDataGrid-cell--editing {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.MuiDataGrid-cell {
  cursor: pointer;
  border-color: #f2f2f2 !important;
}

.MuiDataGrid-cellContent {
  white-space: break-spaces;
  overflow-x: visible;
}

.MuiDataGrid-cell:focus .MuiDataGrid-cellContent {
  overflow: visible;
}

.MuiDataGrid-menu > .MuiPaper-root {
  border-radius: 8px !important;
  box-shadow: rgb(0, 0, 0, 0.08) 0px 2px 11px 0px !important;
}
